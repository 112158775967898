import { GET_PHONE} from '../Type';

const INITIAL = {
  data: {}
}

export default (state = INITIAL, action) => {
  switch (action.type) {
    case GET_PHONE: {
      return { ...state, data: action.data};
    }
    default:
      return state;
  }
}