import React from 'react';
import './WhyUs.scss';
import whyus1 from '../../../Assets/Home/Whyus/whyus1.png';
import whyus2 from '../../../Assets/Home/Whyus/whyus2.png';
import whyus3 from '../../../Assets/Home/Whyus/whyus3.png';
import whyus4 from '../../../Assets/Home/Whyus/whyus4.png';
import whyus5 from '../../../Assets/Home/Whyus/whyus5.png';
import whyus6 from '../../../Assets/Home/Whyus/whyus6.png';
import whyus7 from '../../../Assets/Home/Whyus/whyus7.png';
import up_icon from '../../../Assets/Icons/up_icon.png';
import down_icon from '../../../Assets/Icons/down_icon.png';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const Category = [
    { num: '01', img: whyus1, title: '3 guaranteed trial lessons', description: "We offer a 100% money back guarantee to our students and families if you're not satisfied with your tutor.", isSelect: true },
    { num: '02', img: whyus2, title: '100% refund if there is no progress after three lessons', description: 'Over 98% of our parents and students reuse our services and also refer us to their friends and families.', isSelect: false },
    { num: '03', img: whyus3, title: 'Complementary consultations', description: 'Our prices are competitive and we price match other companies similar to us.', isSelect: false },
    { num: '04', img: whyus4, title: 'Free assessment upon request', description: 'We provide both online and in person tutoring.', isSelect: false },
    { num: '05', img: whyus5, title: 'Experience, passionate and motivated tutors', description: 'Vigourous screening and vetting process before a new tutor can join the FrogTutoring family.', isSelect: false },
    { num: '06', img: whyus6, title: '1-on-1 In-person & online tutoring', description: 'Customized one on one tutoring for both traditional and non traditional students.', isSelect: false },
    { num: '07', img: whyus7, title: 'Track record of helping students make better grades and improving academic confidence', description: 'We offer a free consultation with our student success director to make sure program is a good fit before on boarding you.', isSelect: false },
    { num: '08', img: whyus7, title: 'Detailed reporting and progress tracking', description: 'We offer free diagnostic assessment to select group of students in K-12 in specific subjects.', isSelect: false },
]
class WhyUs extends React.Component {

    state = {
        select_index: '01',
        Category: Category,
        isSeeMore: false,
        select_item: Category[0]
    }

    selectCategory(item, index) {
        Category[index].isSelect = !this.state.Category[index].isSelect
        this.setState({ Category })
        this.setState({
            select_index: item.num,
            select_item: Category[index]
        })
    }

    seeMore() {
        this.setState({ isSeeMore: !this.state.isSeeMore });
    }

    render() {
        const { select_index, select_item, Category, isSeeMore } = this.state;
        const title = this.props.subject && this.props.subject != 'private' ? 'Why Use Us For '+this.props.subject+'?' : 'Why Us?';
        return (
            <div className="whyus-component">
                <div className="container">
                    <h1 className="text-center">{title}</h1>
                    <div className="row">
                        <div className="col-lg-6">
                            {Category.map((item, index) => (
                                (isSeeMore || !isSeeMore && index < 5) && <div key={index}>
                                    <div className={`category-item shadow-object show-web ${item.num === select_index ? "category-active" : ""}`} onClick={() => this.selectCategory(item, index)}>
                                        <div className="v-c">
                                            <div className="num-container v-c h-c mr-3">{item.num}</div>
                                            <div className="item-description">{item.title}</div>
                                        </div>
                                    </div>
                                    <div className={`category-item shadow-object show-mobile ${item.isSelect ? "category-active" : ""}`} onClick={() => this.selectCategory(item, index)}>
                                        <div className="v-c">
                                            <div className="num-container v-c h-c mr-3">{item.num}</div>
                                            <div className="item-description">{item.title}</div>
                                        </div>
                                        <div className="show-mobile">
                                            {item.isSelect ?
                                                <img src={up_icon} alt="brand" className="" />
                                                :
                                                <img src={down_icon} alt="brand" className="" />
                                            }
                                        </div>
                                    </div>
                                    <div className={`show-mobile ${item.isSelect ? "detail-active" : "detail-inActive"}`}>
                                        <img src={item.img} alt="brand" className="logo" />
                                        <p className="text-center mt-3 mb-3">{item.description}</p>
                                    </div>
                                </div>
                            ))}
                            <div className="see-more" onClick={() => this.seeMore()}>{isSeeMore ? "SEE LESS" : "SEE MORE"}</div>
                        </div>
                        <div className="col-lg-6 show-web">
                            <div className="right-area">
                                <img src={select_item.img} alt="brand" className="" />
                                <p className="text-center mt-3">{select_item.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, {})(withRouter(WhyUs));
