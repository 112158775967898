import { UPDATE_PLAN, UPDATE_PLAN_SUCCESS, UPDATE_PLAN_FAIL, AUTH_UPDATE_PLAN, GET_SUBJECT, GET_SUBJECT_FAIL, GET_SUBJECT_SUCCESS } from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
    getSubject as getSubjectApi,
} from '../../Api';

function* getSubject(payload){
    try {
        const result = yield getSubjectApi();
        if (result.message && result.message.length > 0) {
            yield put({ type: GET_SUBJECT_FAIL, data: {msg: result.message}})
        } else {
            yield put({ type: GET_SUBJECT_SUCCESS, data: result})
        }
    } catch (err) {
        yield put({ type: GET_SUBJECT_FAIL, data: {msg: 'NET ERROR'} })
    }
}

export function* watchGetSubject(){
    yield takeLatest(GET_SUBJECT, getSubject)
}
