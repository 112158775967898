/* @flow */

import { combineReducers } from 'redux';

import Modal from './Modal';
import Location from  './Location';
import Review from './Review';
import Request from './Request';
import Subject from './Subject';
import Pricing from './Pricing';
import Pickup from './Pickup';
import Phone from './Phone';
import Landing from './Landing';
import Levels from './Levels';

export default combineReducers ({
    Modal,
    Location,
    Review,
    Request,
    Subject,
    Pricing,
    Pickup,
    Phone,
    Landing,
    Levels,
})