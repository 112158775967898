import React from 'react';
import './MultiSelect.scss';
import check from '../../Assets/Icons/check.png';
import up_icon from '../../Assets/Icons/up_icon.png';
import down_icon from '../../Assets/Icons/down_icon_white.png';
import cancel_subject from '../../Assets/Icons/cancel_subject.png';
import _ from 'lodash'
export default class MultiSelect extends React.Component {
    state = {
        show_drop: false,
        className: this.props.className || '',
        value: '',
        icon: this.props.icon || '',
        placeholder: this.props.placeholder || '',
        type: this.props.type || 'text',
        focused: false,
        options: this.props.options || []
    }

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    toggleDropList() {
        this.setState({ show_drop: !this.state.show_drop })
    }

    onChange = (e) => {
        this.setState({ value: e.target.value });
    }

    selectItem = (item, index) => {
        var selectIndex = _.findIndex(this.state.options, { label: item.label });
        this.state.options[selectIndex].checked = !item.checked
        this.setState({ options: this.state.options })
        this.props.onChange(this.state.options);
    }

    cancelSubject = (item, index) => {
        var selectIndex = _.findIndex(this.state.options, { label: item.label });
        this.state.options[selectIndex].checked = false
        this.setState({ options: this.state.options })
        this.props.onChange(this.state.options);
    }

    render() {
        const { className, value, icon, placeholder, type, focused, options, show_drop } = this.state;
        var list = options && options.filter(item => item.value.toLowerCase().indexOf(value.toLowerCase()) > -1);

        return (
            <div className={`multiSelect-component round-object ${className} ${focused ? 'focused' : ''}`}>
                <div className="selected-subject-content v-c" onClick={() => this.toggleDropList()}>
                    <div className="subject-list-content">
                        {options.map((item, index) => (
                            item.checked && <div className="selected-subject v-c mr-3" key={index} onClick={() => this.cancelSubject(item, index)}>
                                <div className="subject">{item.value}</div>
                                <div><img src={cancel_subject} alt="close-icon" className="close-icon ml-2" /></div>
                            </div>
                        ))}
                    </div>
                    <div className="arrow-content v-c h-c" >
                        <img src={show_drop ? up_icon : down_icon} alt="check-icon" className="check-icon" />
                    </div>
                </div>
                {show_drop && <div className="drop-container">
                    <div className="input-area v-r">
                        <input className="custom-input"
                            placeholder={'Search...'}
                            value={value}
                            onChange={e => this.onChange(e)}
                        />
                    </div>
                    <div className="subject-list-area">
                        {list.map((item, index) => (
                            <div className="item-content v-c" key={index} onClick={() => this.selectItem(item, index)}>
                                <div className={`check-icon-content v-c h-c ${item.checked ? 'active-check' : ''}`} >
                                    {item.checked && <img src={check} alt="check-icon" className="check-icon" />}
                                </div>
                                <div className="item-description ml-3">
                                    {item.value}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>}
            </div>
        )
    };
};
