import React from 'react';
import './RequestTutor.scss';
import logoActive from '../../Assets/Icons/logo-inactive.png';
import { ReactSVG } from 'react-svg';
import left from '../../Assets/Icon/arrow-left.svg';
import { InputBox, SelectBtn } from '../../Components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Yes_StepData, No_StepData, Normal_StepData } from './Step';
import { getSubject, getLevels, requestTutor, toggleHomeModal } from '../../Redux/Actions';

import Helper from '../../Utils/Helper';
import _ from 'lodash'

class RequestTutor extends React.Component {
    state = {
        step: 1,
        request_category: 'yes',
        Data: Yes_StepData,
        purpose: '',
        ing: '',
        start_date: '',
        level_id: '',
        full_name: '',
        email: '',
        phone: '',

        country: 'US',

        searchText: "",
        filterSubjects: [],
        selectedSubjectId: "",

        subject_options: [],
        default_subject: ''
    }

    componentDidMount() {
        Helper.showSpinner();
        this.props.getSubject();
        this.props.getLevels();

        sessionStorage.setItem("doneHomeModal", true);
        this.props.toggleHomeModal(false);

        // if (_.isEmpty(this.props.levels)) {
        //     this.props.getLevels();
        // };
        const { request_category, subject } = this.props.location;
        if (request_category === 'yes') {
            this.setState({
                Data: Yes_StepData,
                request_category: 'yes'
            });
        }

        if (request_category === 'no') {
            this.setState({
                Data: No_StepData,
                request_category: 'no'
            });
        }

        if (request_category === 'normal') {
            this.setState({
                Data: Normal_StepData,
                request_category: 'normal'
            });
        }

        if (request_category === 'schedule') {
            this.setState({
                Data: Normal_StepData,
                request_category: 'normal',
                step: 7
            });
        }

        if (subject) {
            this.setState({
                default_subject: subject
            })
        }


        window.addEventListener('message', this.onWindowMessage);
    };

    componentWillUnmount() {
        window.removeEventListener('message', this.onWindowMessage);
    };

    onWindowMessage = (e) => {
        if (e.data.event && e.data.event.indexOf('calendly') === 0) {
            if (e.data.event === 'calendly.event_scheduled') {
                // setTimeout(() => {
                //     this.props.history.push('/');
                // }, 1000);
            };
        };
    };

    componentDidUpdate(prevProps) {
        if (prevProps.get_subject_loading && !this.props.get_subject_loading) {
            if (this.props.get_subject_success) {
                Helper.hideSpinner();
                var subject_options = [];
                const { subject_list } = this.props;
                Object.keys(subject_list).map((keyName, i) => {
                    subject_options[i] = {
                        label: subject_list[keyName],
                        value: subject_list[keyName],
                        id: keyName
                    };
                })
                this.setState({ subject_options })
            }
        }

        if (prevProps.request_loading && !this.props.request_loading) {
            Helper.hideSpinner();
            if (this.props.request_success) {
                Helper.showToast('success', 3000, "Thank you for your request!")
                // this.props.history.push('/request-tutor/schedule')
                window.location.href = "https://hellofrogtutoring.com/request-tutor-schedule/";

            } else {
                Helper.showToast('error', 3000, this.props.request_message)
            }
        }
    };

    selectItem = async (field, val, ing, ing_value) => {
        this.setState({ [field]: val });
        this.setState({ [ing]: ing_value });

        setTimeout(() => {
            this.moveForward()
        }, 200);
    }

    onChange = (field, val) => {
        this.setState({ [field]: val });
    };

    changeSearchText = (text) => {
        const { subject_options } = this.state
        this.setState({ searchText: text });
        const filterSubjects = subject_options.filter(
            item => item.value.toLowerCase().indexOf(text.toLowerCase()) > -1,
        );
        this.setState({ filterSubjects })
    };

    onSelectSubject = (item) => {
        const { step } = this.state;
        this.setState({
            selectedSubjectId: item.id,
            default_subject: item.label
        })

        setTimeout(() => {
            this.setState({ step: step + 1 })
        }, 500);
    }

    alphabetical(index) {
        switch (index) {
            case 0:
                return 'A';
            case 1:
                return 'B';
            case 2:
                return 'C';
            case 3:
                return 'D';
            case 4:
                return 'E';
            case 5:
                return 'F';
            case 6:
                return 'F';
            default:
                return 'G';
        }
    }

    moveForward = () => {
        const { request_category, step, purpose, start_date, level_id, full_name, email, phone } = this.state;
        if (request_category === 'yes') {
            if (step === 1 && purpose) {
                this.setState({ step: step + 1 })
            }
            if (step === 2 && start_date) {
                this.setState({ step: step + 1 })
            }
            if (step === 3) {
                if (level_id) {
                    this.setState({ step: step + 1 })
                } else {
                    Helper.showToast('error', 3000, "Please select the Type of tutoring")
                }
            }
            if (step === 4 && full_name) {
                this.setState({ step: step + 1 })
            }
            if (step === 5 && email && phone) {
                // this.setState({step: step + 1})
                this.submit()
            }
            if (step === 6) {
                this.setState({ step: step + 1 })
            }
            if (step === 7) {
                // this.submit()
            }
        } else {
            if (step === 1) {
                if (level_id) {
                    this.setState({ step: step + 1 })
                } else {
                    Helper.showToast('error', 3000, "Please select the Type of tutoring")
                }
            }
            if (step === 2) {
                //
            }
            if (step === 3 && purpose) {
                this.setState({ step: step + 1 })
            }
            if (step === 4 && start_date) {
                this.setState({ step: step + 1 })
            }
            if (step === 5 && full_name) {
                this.setState({ step: step + 1 })
            }
            if (step === 6 && email && phone) {
                // this.setState({step: step + 1})
                this.submit()
            }
            if (step === 7) {
                this.setState({ step: step + 1 })
            }
            if (step === 8) {
                // this.submit()
            }
        }
    };

    goBack = () => {
        this.setState({ step: this.state.step !== 1 ? this.state.step - 1 : this.state.step });
        if (this.state.step === 1) {
            window.history.back();
        }
    };

    submit() {
        Helper.showSpinner();

        const { purpose, start_date, level_id, full_name, email, phone, default_subject } = this.state;

        const formData = new FormData();
        formData.append('name', full_name);
        formData.append('email', email);
        formData.append('phone', phone);
        formData.append('subject', _.isEmpty(default_subject) ? this.props.Landing1Data.subject : default_subject);
        formData.append('zipcode', "");
        formData.append('info', `User want tutor to ${purpose} User want to start ${start_date} ${!_.isEmpty(default_subject) ? `User want additional subject: ${default_subject}` : ""}`);
        formData.append('region', this.props.Landing1Data.city);
        formData.append('level_id', level_id);
        formData.append('client_id', "This is Google Adword Client ID");
        formData.append('origin', "Request from landing2021");
        formData.append('signup_source', "adwords landing page");
        const recommendation_id = localStorage.getItem('recommendation_id');
        if (recommendation_id) formData.append('recommendation_id', recommendation_id);
        this.props.requestTutor(formData);
    }

    renderStep = (Datas) => {
        const { filterSubjects, selectedSubjectId, step, subject_options, default_subject, ing, full_name } = this.state;
        const { levels } = this.props;
        return Datas.map((info, dataIndex) => step === info.step && <div className="row" id={`section-${info.step}`} key={dataIndex}>
            <div className="col-lg-5 order-lg-1 order-2 left-col v-c h-c show-web-flex">
                <img src={info.ads_img} alt="ads" className="ads-img" />
            </div>
            <div className={`col-lg-7 order-lg-2 order-1 right-col v-r h-c`}>
                <div className="greating mb-2">
                    {info.purpose ? `${info.greating} ${ing}` :
                        info.normalStep ? `${info.greating} ${default_subject}.` :
                            info.thankyou ? `Hi ${full_name}! Thank you for your trusting us with your ${default_subject} tutoring needs.` : info.greating}
                </div>
                <h1>
                    {info.normalStep2Title ? `What are your goals for ${default_subject} tutoring?` :
                        info.subjectChangingTitle ? `Now, what are your goals for getting a ${default_subject} tutor?` : info.title}
                </h1>
                <div className="greating mb-2">{info.description}</div>
                {(info.selections && info.selections.length > 0) && <div className="item-container">
                    {info.selections.map((item, index) => <div className={`select-item ${item.value === this.state[info.state_val] ? 'active' : ''}`} key={index} onClick={e => this.selectItem(info.state_val, item.value, info.ing && 'ing', item.ing)}>
                        <div className="order v-c h-c mr-3">{item.order}</div>
                        <div className="value">{item.value}</div>
                    </div>)}
                </div>}
                {(info.level && !_.isEmpty(levels)) && <div className="item-container">
                    {Object.keys(levels).map((keyName, index) => <div className={`select-item level-item ${keyName === this.state[info.state_val] ? 'active' : ''}`} key={index} onClick={e => this.selectItem(info.state_val, keyName)}>
                        <div className="order v-c h-c mr-3">{this.alphabetical(index)}</div>
                        <div className="value">{levels[keyName]}</div>
                    </div>)}
                </div>}
                <div className="sub-section v-r">
                    {info.input && <InputBox className="input-box" type={info.password ? 'password' : ''} placeholder={info.placeholder} value={this.state[info.state_val]} onChange={e => this.onChange(info.state_val, e)} />}
                    {info.doubleInput && <div className="email-container">
                        <InputBox className="input-box mr-3" placeholder={info.placeholder1} value={this.state[info.state_val1]} onChange={e => this.onChange(info.state_val1, e)} />
                        <InputBox className="input-box" placeholder={info.placeholder2} phone_input type="number" value={this.state[info.state_val2]} country={this.state[info.state_country]} onSelect={e => this.onChange(info.state_country, e)} onChange={e => this.onChange(info.state_val2, e)} />
                    </div>}
                    {info.searchSelect && !_.isEmpty(subject_options) &&
                        <div>
                            <InputBox
                                className="input-box"
                                placeholder={info.placeholder}
                                value={this.state.searchText}
                                onChange={e => this.changeSearchText(e)}
                            />
                            <div className='drop-content'>
                                {!_.isEmpty(filterSubjects) && (
                                    filterSubjects.map((item, index) => (
                                        <SelectBtn
                                            key={index}
                                            className='select-btn'
                                            item={item}
                                            selectedId={selectedSubjectId}
                                            onClick={this.onSelectSubject}
                                        />
                                    ))
                                )}
                            </div>
                        </div>
                    }
                    {info.phone && <InputBox className="input-box" phone_input type="number" placeholder={info.placeholder} value={this.state[info.state_val]} country={this.state[info.state_country]} onSelect={e => this.onChange(info.state_country, e)} onChange={e => this.onChange(info.state_val, e)} />}
                </div>
                {info.btn && <button className="btn-2 primary-btn" onClick={e => this.moveForward()}> {info.btn} </button>}
                {(info.continue && !this.props.signup_loading) &&
                    <div className="next-btn v-c">
                        <div className="v-c back-btn" onClick={e => this.goBack()}><ReactSVG src={left} className="svg-icon" />&nbsp;Back</div>
                        {!info.searchSelect && <div className="btn continue-btn v-c h-c" onClick={e => this.moveForward()}>{info.finalBtn ? "Done" : "CONTINUE"}</div>}
                    </div>
                }
            </div>
        </div>)
    }

    clickLogo() {
        // window.location.href = "https://frogtutoring.com/";
    }

    render() {
        const { Data } = this.state;
        return (
            <div className="request-page">
                <div className="container v-r">
                    <div className="logo-area">
                        <div className="btn" onClick={() => this.clickLogo()}><img src={logoActive} alt="logo" /></div>
                    </div>
                    <div className="main-content">
                        {Data && this.renderStep(Data)}
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
        get_subject_success: state.Subject.get_subject_success,
        get_subject_loading: state.Subject.get_subject_loading,
        subject_list: state.Subject.subject_list,
        levels: state.Levels.levels,

        request_loading: state.Request.request_loading,
        request_success: state.Request.request_success,
        request_message: state.Request.request_message,

        Landing1Data: state.Landing.Landing1Data,
    }
}

export default connect(mapStateToProps, {
    getLevels,
    getSubject,
    requestTutor,
    toggleHomeModal,
})(withRouter(RequestTutor));