import {
    GET_LEVELS, GET_LEVELS_SUCCESS, GET_LEVELS_FAIL,
} from '../Type';
import { takeLatest, put } from 'redux-saga/effects';
import {
    getLevels as getLevelsApi
} from '../../Api';

function* getLevels(payload){
    try {
        const result = yield getLevelsApi(payload.page)
        if (result) {
            yield put({ type: GET_LEVELS_SUCCESS, data: result })
        } else {
            yield put({ type: GET_LEVELS_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_LEVELS_FAIL, data: [] })
    }
}

export function* watchGetLevels(){
    yield takeLatest(GET_LEVELS, getLevels)
}