import { GET_LEVELS, GET_LEVELS_SUCCESS, GET_LEVELS_FAIL } from '../Type';

const INITIAL = {
  levels: null,
  get_levels_loading: false,
  get_levels_success: false
}

export default (state = INITIAL, action) => {
  switch (action.type) {
    case GET_LEVELS: {
      return {
        get_levels_loading: true,
        get_levels_success: false,
      }
    }
    case GET_LEVELS_FAIL: {
        return {
          levels: null,
          get_levels_loading: false,
          get_levels_success: false
        }
    }
    case GET_LEVELS_SUCCESS: {
      return {
        levels: action.data,
        get_levels_loading: false,
        get_levels_success: true
      }
    }

    default:
        return state;
  }
}