import { SERVICE_MODAL_TOGGLE, HOME_MODAL_TOGGLE, REQUEST_MODAL_TOGGLE, TUTOR_MODAL_TOGGLE, VIDEO_MODAL_TOGGLE, FEEDBACK_MODAL_TOGGLE, ENABLE_BOTTOM_MODAL } from '../Type';

export const toggleServiceModal = (isOpen, info = {}) => {
  return {
    type: SERVICE_MODAL_TOGGLE,
    data: { isOpen, info }
  }
}

export const toggleHomeModal = (isOpen, info) => {
  return {
    type: HOME_MODAL_TOGGLE,
    data: { isOpen, info }
  }
}

export const enableBottomModal = (isOpen) => {
  return {
    type: ENABLE_BOTTOM_MODAL,
    data: { isOpen }
  }
}

export const toggleRequestModal = (isOpen) => {
  return {
    type: REQUEST_MODAL_TOGGLE,
    data: { isOpen }
  }
}

export const toggleVideoModal = (isOpen, info = null) => {
  return {
    type: VIDEO_MODAL_TOGGLE,
    data: { isOpen, info }
  }
}

export const toggleTutorModal = (isOpen, info = null, rect = null) => {
  return {
    type: TUTOR_MODAL_TOGGLE,
    data: { isOpen, info, rect }
  }
}

export const toggleFeedbackModal = (isOpen, info = {}) => {
  return {
    type: FEEDBACK_MODAL_TOGGLE,
    data: { isOpen, info }
  }
}