import React from 'react';
import './Header.scss';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReactSVG } from 'react-svg'

import location_icon from '../../Assets/Header/location_icon.png';
import phone from '../../Assets/Header/phone-call.png';
import phone_mobile from '../../Assets/Header/phone_mobile.png';
import logoActive from '../../Assets/Icons/logo-active.png';
import logoInactive from '../../Assets/Icons/logo-inactive.png';
import { getPhoneNumber, enableBottomModal } from '../../Redux/Actions';

class Header extends React.Component {
    state = {
        transform: false,
    }

    static getDerivedStateFromProps(props, state) {
        return {
            data: props.data.data,
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    
    handleScroll = (event) => {
        this.setState({
            transform: window.scrollY > 0 ? true : false
        });
        if(window.scrollY > 0){
            this.props.enableBottomModal(true);
        }
    }

    showInactiveHeader = () => {
        const pathName = this.props.location.pathname;
        return (
            pathName === '/' || 
            pathName === '/local-tutors' || 
            pathName === '/online-tutors' || 
            pathName === '/facebook'
        ) && !this.state.transform
    }

    hideHeader = () => {
        const pathName = this.props.location.pathname;
        return (
            pathName === '/request-tutor' || pathName === '/request-tutor/schedule' ||
            pathName === '/request-tutor/welcome'
        ) 
    }

    logo = () => {
        //window.location.href = "https://frogtutoring.com/";
    }

    request(){
        this.props.history.push({
            pathname: '/request-tutor',
            request_category: "normal",
            subject: this.props.subject
        });
    }

    render() {
        const { data } = this.props;

        return (
            <div className={`header-component v-c ${this.showInactiveHeader() ? 'inactive' : ''}`} style={{display: this.hideHeader() && 'none'}}>
                <div className="container">
                    <div className="header-container v-c">
                        <div className="v-c">
                            <div className="menu-item logo-active logo" onClick={()=>this.logo()}><img src={logoActive} alt="logo-active" className="logo-active-img"/></div>
                            <div className="menu-item logo-inactive logo" onClick={()=>this.logo()}><img src={logoInactive} alt="logo-inactive" /></div>
                            <img src={location_icon} alt="phone" className="ml-4"/>
                            <div className="menu-item ml-2">{data.city}</div>
                        </div>
                        <div className="v-c">                      
                            <img src={phone} alt="phone" className="show-web"/>
                            <a href={`tel:+1${data.phone}`}>
                                <img src={phone_mobile} alt="phone" className="show-mobile"/>
                            </a>
                            <div className="phone-txt ml-2 show-web">{data.phone}</div>
                            <div className="request-tutor v-c h-c shadow-object ml-4 show-web-flex btn" onClick={()=>this.request()}>REQUEST TUTOR INFO</div>
                        </div>
                    </div>                   
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        data: state.Phone.data,
    }
}

export default connect(mapStateToProps, { 
    getPhoneNumber,
    enableBottomModal
})(withRouter(Header));
