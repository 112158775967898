import { SERVICE_MODAL_TOGGLE, HOME_MODAL_TOGGLE, REQUEST_MODAL_TOGGLE, TUTOR_MODAL_TOGGLE, VIDEO_MODAL_TOGGLE, FEEDBACK_MODAL_TOGGLE, ENABLE_BOTTOM_MODAL } from '../Type';

const INITIAL = {
  isServiceModalOpen: false,
  isHomeModalOpen: false,
  info: '',
  isTutorModalOpen: false,
  isRequestModalOpen: true,
  isVideoModalOpen: false,
  isFeedbackModalOpen: false,
  isBottomModal: false
}

export default (state = INITIAL, action) => {
  switch (action.type) {
    case SERVICE_MODAL_TOGGLE: {
      const { isOpen, info } = action.data;
      return { ...state, isServiceModalOpen: isOpen, info };
    }

    case HOME_MODAL_TOGGLE: {
      const { isOpen, info } = action.data;
      return { ...state, isHomeModalOpen: isOpen, info: info };
    }

    case REQUEST_MODAL_TOGGLE: {
      const { isOpen } = action.data;
      return { ...state, isRequestModalOpen: isOpen };
    }

    case ENABLE_BOTTOM_MODAL: {
      const { isOpen } = action.data;
      return { ...state, isBottomModal: isOpen };
    }

    case VIDEO_MODAL_TOGGLE: {
      const { isOpen, info } = action.data;
      return { ...state, isVideoModalOpen: isOpen, info };
    }

    case TUTOR_MODAL_TOGGLE: {
      const { isOpen, info, rect } = action.data;
      return { ...state, isTutorModalOpen: isOpen, info, rect };
    }

    case FEEDBACK_MODAL_TOGGLE: {
      const { isOpen, info } = action.data;
      return { ...state, isFeedbackModalOpen: isOpen, info };
    }

    default:
      return state;
  }
}