import Step0 from '../../Assets/Request/Step0.png';
import Step1 from '../../Assets/Request/Step1.png';
import Step2 from '../../Assets/Request/Step2.png';
import Step3 from '../../Assets/Request/Step3.png';
import Step4 from '../../Assets/Request/Step4.png';
import Step5 from '../../Assets/Request/Step5.png';
import Step6 from '../../Assets/Request/Step6.png';

export const Yes_StepData = [
    {
        step: 1,
        greating: "Great! That's what I thought.",
        title: "",
        subjectChangingTitle: true,
        selections: [
            {order: 'A', value: 'Make better grades', ing: 'making better grades' },
            {order: 'B', value: 'Pass an exam', ing: 'passing an exam'}, 
            {order: 'C', value: 'Improve confidence', ing: 'improving confidence'}, 
            {order: 'D', value: 'Learn something new', ing: 'learing Something New'}, 
            {order: 'E', value: 'Get ahead Academically', ing: 'getting ahead accdemically'},
        ],
        state_val: 'purpose',
        ing: true,
        ads_img: Step1,
        continue: true
    },
    {
        step: 2,
        greating: "Thanks for sharing your tutoring goals!",
        title: "How soon do you want to get started?",
        selections: [
            {order: 'A', value: 'Today', },
            {order: 'B', value: 'This week'}, 
            {order: 'C', value: 'Next week'}, 
            {order: 'D', value: 'Next month'}, 
            {order: 'E', value: 'Not sure'},
        ],
        state_val: 'start_date',
        ads_img: Step2,
        continue: true
    },
    {
        step: 3,
        greating: "We can handle that time frame!",
        title: "Type of tutoring?",
        level: true,
        state_val: 'level_id',
        ads_img: Step3,
        continue: true
    },
    {
        step: 4,
        greating: "Good news 😎! We have experienced tutors with success rates of over 98% who can help with.",
        purpose: true,
        title: "What is your name? Our tutors and Student Success Advocate would like to address you correctly.",
        state_val: 'full_name',
        ads_img: Step4,
        input: true,
        placeholder: 'Full name',
        continue: true
    },
    {
        step: 5,
        greating: "",
        thankyou: true,
        title: "Our Student Success Advocate would like to give you a program overview and also answer your questions. Would you please provide your contact info.",
        ads_img: Step5,
        doubleInput: true,
        state_val1: 'email',
        placeholder1: 'Email',
        state_val2: 'phone',
        placeholder2: 'Phone',
        state_country: 'country',
        country: 'US',
        continue: true,
        finalBtn: true
    },  
    {
        step: 6,
        greating: "",
        title: "Thank you for reaching out!",
        description: 'Expect a call from our very fiendly student success advocate shortly or you can schedule a time that works best for you using our consultation calendar below',
        schedule_plugin: true,
        ads_img: Step5,
        btn: 'SCHEDULE'
    },  
    {
        step: 7,
        greating: "",
        title: "Thank you for reaching out!",
        description: 'While you wait to hear from our student success advocate, you can review profiles of tutors',
        ads_img: Step6,
        btn: 'SHOW ME PROFILES OF TUTORS'
    }, 
];

export const No_StepData = [
    {
        step: 1,
        greating: "Sorry we got it wrong! We can handle that time frame!",
        title: "Type of tutoring?",
        level: true,
        state_val: 'level_id',
        ads_img: Step3,
        continue: true
    },
    {
        step: 2,
        greating: "Thanks for trusting us with your tutoring needs. Now let's get you match with an awesome tutor.",
        title: "What subject do you need a tutor for?",
        state_val: 'subject',
        searchSelect: true,
        placeholder: 'Search Subject',
        continue: true,
        ads_img: Step0,
    },
    {
        step: 3,
        greating: "Great! That's what I thought.",
        title: "",
        subjectChangingTitle: true,
        selections: [
            {order: 'A', value: 'Make better grades', ing: 'making better grades' },
            {order: 'B', value: 'Pass an exam', ing: 'passing an exam'}, 
            {order: 'C', value: 'Improve confidence', ing: 'improving confidence'}, 
            {order: 'D', value: 'Learn something new', ing: 'learing Something New'}, 
            {order: 'E', value: 'Get ahead Academically', ing: 'getting ahead accdemically'},
            {order: 'F', value: 'Other', ing: 'Other'},
        ],
        state_val: 'purpose',
        ing: true,
        ads_img: Step1,
        continue: true
    },
    {
        step: 4,
        greating: "Thanks for sharing your tutoring goals!",
        title: "How soon do you want to get started?",
        selections: [
            {order: 'A', value: 'Today', },
            {order: 'B', value: 'This week'}, 
            {order: 'C', value: 'Next week'}, 
            {order: 'D', value: 'Next month'}, 
            {order: 'E', value: 'Not sure'},
        ],
        state_val: 'start_date',
        ads_img: Step2,
        continue: true
    },    
    {
        step: 5,
        greating: "Good news 😎! We have experienced tutors with success rates of over 98% who can help with.",
        purpose: true,
        title: "What is your name? Our tutors and Student Success Advocate would like to address you correctly.",
        state_val: 'full_name',
        ads_img: Step4,
        input: true,
        placeholder: 'Full name',
        continue: true
    },
    {
        step: 6,
        greating: "",
        thankyou: true,
        title: "Our Student Success Advocate would like to give you a program overview and also answer your questions. Would you please provide your contact info.",
        ads_img: Step5,
        doubleInput: true,
        state_val1: 'email',
        placeholder1: 'Email',
        state_val2: 'phone',
        placeholder2: 'Phone',
        state_country: 'country',
        country: 'US',
        continue: true,
        finalBtn: true
    },   
    {
        step: 7,
        greating: "",
        title: "Thank you for reaching out!",
        description: 'Expect a call from our very fiendly student success advocate shortly or you can schedule a time that works best for you using our consultation calendar below',
        schedule_plugin: true,
        ads_img: Step5,
        btn: 'SCHEDULE'
    },  
    {
        step: 8,
        greating: "",
        title: "Thank you for reaching out!",
        description: 'While you wait to hear from our student success advocate, you can review profiles of tutors',
        ads_img: Step6,
        btn: 'SHOW ME PROFILES OF TUTORS'
    }, 
];

export const Normal_StepData = [
    {
        step: 1,
        greating: "We can handle that time frame!",
        title: "Type of tutoring?",
        level: true,
        state_val: 'level_id',
        ads_img: Step3,
        continue: true
    },
    {
        step: 2,
        greating: "Thanks for trusting us with your tutoring needs. Now let's get you match with an awesome tutor.",
        title: "What subject do you need a tutor for?",
        state_val: 'subject',
        searchSelect: true,
        placeholder: 'Search Subject',
        continue: true,
        ads_img: Step0,
    },
    {
        step: 3,
        greating: "Great! We can help with ",
        normalStep: true,
        title: "What are your goals for getting a  tutor?",
        normalStep2Title: true,
        selections: [
            {order: 'A', value: 'Make better grades', ing: 'making better grades' },
            {order: 'B', value: 'Pass an exam', ing: 'passing an exam'}, 
            {order: 'C', value: 'Improve confidence', ing: 'improving confidence'}, 
            {order: 'D', value: 'Learn something new', ing: 'learing Something New'}, 
            {order: 'E', value: 'Get ahead Academically', ing: 'getting ahead accdemically'},
            {order: 'F', value: 'Other', ing: 'Other'},
        ],
        state_val: 'purpose',
        ing: true,
        ads_img: Step1,
        continue: true
    },
    {
        step: 4,
        greating: "Thanks for sharing your tutoring goals!",
        title: "How soon do you want to get started?",
        selections: [
            {order: 'A', value: 'Today', },
            {order: 'B', value: 'This week'}, 
            {order: 'C', value: 'Next week'}, 
            {order: 'D', value: 'Next month'}, 
            {order: 'E', value: 'Not sure'},
        ],
        state_val: 'start_date',
        ads_img: Step2,
        continue: true
    },    
    {
        step: 5,
        greating: "Good news 😎! We have experienced tutors with success rates of over 98% who can help with.",
        purpose: true,
        title: "What is your name? Our tutors and Student Success Advocate would like to address you correctly.",
        state_val: 'full_name',
        ads_img: Step4,
        input: true,
        placeholder: 'Full name',
        continue: true
    },
    {
        step: 6,
        greating: "",
        thankyou: true,
        title: "Our Student Success Advocate would like to give you a program overview and also answer your questions. Would you please provide your contact info.",
        ads_img: Step5,
        doubleInput: true,
        state_val1: 'email',
        placeholder1: 'Email',
        state_val2: 'phone',
        placeholder2: 'Phone',
        state_country: 'country',
        country: 'US',
        continue: true,
        finalBtn: true
    },   
    {
        step: 7,
        greating: "",
        title: "Thank you for reaching out!",
        description: 'Expect a call from our very fiendly student success advocate shortly or you can schedule a time that works best for you using our consultation calendar below',
        schedule_plugin: true,
        ads_img: Step5,
        btn: 'SCHEDULE'
    },  
    {
        step: 8,
        greating: "",
        title: "Thank you for reaching out!",
        description: 'While you wait to hear from our student success advocate, you can review profiles of tutors',
        ads_img: Step6,
        btn: 'SHOW ME PROFILES OF TUTORS'
    }, 
];