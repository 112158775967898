import React from 'react';
import ReactDOM from 'react-dom';
import './Tutor.scss';
import bank from  '../../Assets/Icons/bank.png';
import verified from '../../Assets/Icons/verified.png';

export default class Tutor extends React.Component {
    getComponentRect = () => {
        var rect = ReactDOM.findDOMNode(this).getBoundingClientRect();
        if (this.props.onClick) {
            this.props.onClick(rect);
        }
    }

    render() {
        const { tutor_info, className, subject } = this.props;
        console.log(subject, tutor_info);
        let profile_title = tutor_info.title;
        if(tutor_info.tutoring_hour && subject && subject != 'private'){
            let hour = parseInt(tutor_info.tutoring_hour);
            if(hour < 80) hour += 100;
            profile_title = 'Expert <b>' + subject + '</b> Tutor, <b>' + hour + '</b> Hours Tutoring & Counting';
        }
        return (
            <div className={`tutor-component ${className}`} onClick={this.getComponentRect}>
                <div className="info-area">
                    <img src={tutor_info.image} alt="avatar" className="sub-info tutor-avatar" />
                </div>
                <div className="info-area">
                    <div className="sub-info tutor-info">
                        <div className="v-c">
                            <div className="name mr-2">{tutor_info.tutor_name}</div>
                            <div className="mb-1">
                                <img src={verified} alt="verify-mark" />
                            </div>
                        </div>
                        <div className="sub-text" dangerouslySetInnerHTML={{ __html: profile_title }} />
                        {(tutor_info.school && tutor_info.school.length > 0) && 
                            <div className="school">
                                <img src={bank} alt="bank" />
                                <div className="sub-text">{tutor_info.school}</div>
                            </div>
                        }
                        {(tutor_info.about_me && tutor_info.about_me.length > 0) && 
                            <div className="tutor-description">{tutor_info.about_me}</div>
                        }
                    </div>
                </div>
                <div className="bg-dot custom-dot" alt="bgdot" />
            </div>
        );
    }
}
