import React from 'react';
import './Tutors.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Loadable from "@loadable/component";
import Media from "react-media";
import { CarouselLeftButton, CarouselRightButton } from "../../../Constant";
import { Tutor } from "../../";

const OwlCarousel = Loadable(() => import("react-owl-carousel"));
class Tutors extends React.Component {

    render() {
        const { tutors } = this.props;
        const title = this.props.subject && this.props.subject != 'private' ? 'Some of our '+this.props.subject+' Tutors' : 'Some of our Tutors';
        console.log('aaaa');
        return (
            <div className="tutors-component">
                <div className="container">
                    <h1 className="text-center">{title}</h1>
                    <div className="row">
                    <Media
                        queries={{
                            lg: "(min-width: 1200px)",
                            md: "(min-width: 992px) and (max-width: 1199px)",
                            sm: "(min-width: 768px) and (max-width: 991px)",
                            normal: "(max-width: 767px)",
                        }}
                        >
                        {(matches) => (
                            <OwlCarousel
                            className="owl-theme"
                            nav
                            stagePadding={
                                matches.lg ? 0 : matches.md ? 0 : matches.sm ? 0 : 15
                            }
                            margin={
                                matches.lg ? 28 : matches.md ? 33 : matches.sm ? 45 : 20
                            }
                            items={matches.lg ? 4 : matches.md ? 3 : matches.sm ? 2 : 1}
                            dots={false}
                            navText={[CarouselLeftButton, CarouselRightButton]}
                            >
                            {tutors.map((item, index) => {
                                return <Tutor key={index} tutor_info={item} subject={this.props.subject} team={false}/>;
                            })}
                            </OwlCarousel>
                        )}
                        </Media>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, {})(withRouter(Tutors));
