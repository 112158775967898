import {
    GET_LANDING1_DATA, GET_LANDING1_DATA_SUCCESS, GET_LANDING1_DATA_FAIL,
} from '../Type';
import { takeLatest, put } from 'redux-saga/effects';
import {
    getLanding1Data as getLanding1DataApi
} from '../../Api';

function* getLanding1Data(payload){
    try {
        const result = yield getLanding1DataApi(payload.data)
        if (result) {
            yield put({ type: GET_LANDING1_DATA_SUCCESS, data: result })
        } else {
            yield put({ type: GET_LANDING1_DATA_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_LANDING1_DATA_FAIL, data: [] })
    }
}

export function* watchGetLanding1Data(){
    yield takeLatest(GET_LANDING1_DATA, getLanding1Data)
}