import { REQUEST_TUTOR, CALL_BACK } from '../Type';

export const requestTutor = (data) => {
  return {
    type: REQUEST_TUTOR,
    data: data
  }
}

export const callback = (data) => {
  return {
    type: CALL_BACK,
    data: data
  }
}