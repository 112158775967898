import React from 'react';
import './BottomModal.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReactSVG } from 'react-svg'
import close_icon from '../../Assets/Icons/close_icon.png';
import top_close_icon from '../../Assets/Icons/close_icon2.svg';

import green_dot from '../../Assets/Icons/green_dot.png';

import up from '../../Assets/Icons/up.svg';
import { toggleRequestModal } from '../../Redux/Actions';

class BottomModal extends React.Component {
    state ={
        outer: true,
        inner: false
    }

    static getDerivedStateFromProps(props, state) {
        return {
            isOpen: props.isOpen,
            isBottomModal: props.isBottomModal
        }
    }

    componentDidUpdate(){
        // setTimeout(function() {
        //     this.setState({outer: !this.state.outer})
        // }.bind(this), 2*1000)
        // setTimeout(function() {
        //     this.setState({inner: !this.state.inner})
        // }.bind(this), 5*1000)
    }

    closeRequestModal(){
        if(this.state.isOpen){
            sessionStorage.setItem("doneRequest", true);
            this.props.toggleRequestModal(false);
        } else {
            this.request()
        }
    }

    request(){
        this.props.history.push({
            pathname: '/request-tutor',
            request_category: "normal"
        });
    }

    hideHeader = () => {
        const pathName = this.props.location.pathname;
        return (
            pathName === '/request-tutor' ||
            pathName === '/request-tutor/schedule' ||
            pathName === '/request-tutor/welcome'
        ) 
    }

    render() {
        let { isOpen, outer, inner, isBottomModal } = this.state;
        return (
            <div className="bottom-modal-component" style={{display: this.hideHeader() ? 'none' : !this.state.isBottomModal ? "none" : ""}}>
                <div className="bottom-modal-content shadow-object" style={{display: !isOpen && 'none'}}>
                    <div className="title text-center">Why us?</div>
                    <div className="close-btn show-mobile" onClick={ () => this.closeRequestModal() }> 
                        <ReactSVG src={ top_close_icon } className='icon-close'/>
                    </div>
                    <div className="v-c">
                        <img src={green_dot} alt="dot" className=""/>
                        <p>100% money back guarantee</p>
                    </div>
                    <div className="v-c">
                        <img src={green_dot} alt="dot" className=""/>
                        <p>100% tutor satisfaction</p>
                    </div>
                    <div className="v-c">
                        <img src={green_dot} alt="dot" className=""/>
                        <p>1:1 in person and online tutoring</p>
                    </div>
                    <div className="v-c">
                        <img src={green_dot} alt="dot" className=""/>
                        <p>98% success rate since 2009</p>
                    </div>
                    <div className="v-c">
                        <img src={green_dot} alt="dot" className=""/>
                        <p>Tutoring for all subjects & test prep</p>
                    </div>
                    <div className="request-btn btn v-c h-c mt-4" onClick={()=>this.request()}>GET TUTORING INFO</div>
                    <div className="bottom-description mt-3 v-c">We are very friendly! You would love us.</div>
                </div>
                <div className="close-container show-web">
                    <div className={`close-content v-c h-c mt-3 `} onClick={ () => this.closeRequestModal()} >
                        <div className={`close-cover-middle v-c h-c `}>
                            <div className="close-cover-center v-c h-c">
                                {isOpen ? 
                                    <img src={close_icon} alt="dot" className=""/>
                                    :
                                    <div className="v-c h-c text-center">Request tutor info</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
        isOpen: state.Modal.isRequestModalOpen,
        isBottomModal: state.Modal.isBottomModal,
    }
}

export default connect(mapStateToProps, {toggleRequestModal})(withRouter(BottomModal));
