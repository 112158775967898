import React, { useEffect } from 'react';
import './App.scss';
import { BrowserRouter, Switch, Route, useLocation } from "react-router-dom";
import { connect } from 'react-redux';
import BeatLoader from 'react-spinners/BeatLoader'
import { EventEmitter } from './Utils/events';
import { css } from "@emotion/react";
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import {
  Header,
  Footer,
  ServiceModal,
  HomeModal,
  FeedbackModal,
  VideoModal,
  BottomModal
} from './Components';
import {
  LandingPage1,
  LandingPage2,
  LandingPage3,
  RequestTutor,
  SchedulePage,
  WelcomePage
} from './Pages';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const override = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

class App extends React.Component {
  state = {
    isBlur: false,
    isSidebarOpen: true,
    isSidebarPrevOpen: true,
    isResize: false,
    loading: false
  }

  static getDerivedStateFromProps(props, state) {
    return {
      isBlur: props.isMobileSidebarOpen || props.isModalOpen
    }
  }

  render() {
    const { isBlur, loading } = this.state;
    EventEmitter.subscribe('isLoading', (event) => this.setState({ loading: event }));

    if (isBlur) {
      if (document.body) {
        const body = document.body
        body.classList.add('modal-open');
      }
    } else {
      if (document.body) {
        const body = document.body
        body.classList.remove('modal-open');
      }
    }

    return (
      <BrowserRouter basename="/local-tutors/">
        <ScrollToTop />
        <div className="App">
          <div id="main" className={`${isBlur ? 'blur' : ''}`}>
            <Header />
            <div className="content">
              <Switch>
                <Route exact path="/" component={LandingPage1} />
                {/* <Route exact path="/online-tutors" component={LandingPage2} />
                <Route exact path="/facebook" component={LandingPage3} /> */}
                <Route exact path="/request-tutor" component={RequestTutor} />

                <Route exact path="/tutoring" component={LandingPage1} />
                <Route exact path="/tutoring-classes" component={LandingPage1} />
                <Route exact path="/affordable-tutors" component={LandingPage1} />
                <Route exact path="/meet-tutor" component={LandingPage1} />
                <Route exact path="/tutor-near-me" component={LandingPage1} />
                <Route exact path="/schedule-class" component={LandingPage1} />

                {/* <Route exact path="/request-tutor/schedule" component={SchedulePage} /> */}
                {/* <Route exact path="/request-tutor/welcome" component={WelcomePage} /> */}
              </Switch>
            </div>
            <Footer />
            {/* <ServiceModal /> */}
            <HomeModal />
            <FeedbackModal />
            <VideoModal />
            <BottomModal />
          </div>
        </div>
        <div className={loading ? "overlay-loader" : "d-none"}>
          <BeatLoader
            css={override}
            size={30}
            color={"#FFF"}
            loading={loading}
          />
        </div>
        <NotificationContainer />
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isModalOpen: state.Modal.isServiceModalOpen,
  }
}

export default connect(mapStateToProps, {})(App);
