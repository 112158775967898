import React from 'react';
import './VideoModal.scss';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ReactSVG } from 'react-svg'
import { toggleVideoModal } from '../../Redux/Actions';
import close_icon from '../../Assets/Icons/close_icon.svg';


class VideoModal extends React.Component {
    state = {
        isOpen: true,
        info: ''
    }

    componentDidUpdate = async (prevProps) => {
        // if(this.state.isOpen){
        //     const script = document.createElement("script");
        //     script.src = "https://muse.ai/static/js/embed-player.min.js";
        //     script.async = true;
        //     document.body.appendChild(script);
        // }

        // if(this.state.isOpen === false){
        //     window.location.reload();
        // }
     };

    static getDerivedStateFromProps(props, state) {
        return {
            isOpen: props.isOpen,
            info: props.info
        }
    }

    closeModal = () => {
        this.props.toggleVideoModal(false);
        // window.location.reload();
    }

    onHide = () => {
    }

    render() {
        let { isOpen, info } = this.state;
        return (
            <Modal show={isOpen} onHide={this.onHide} className="video-modal-component">
                <div className="video-content v-r">
                    <div className="close-btn" onClick={ () => this.closeModal() }> 
                        <ReactSVG src={ close_icon } className='icon-close'/>
                    </div>
                    {/* <div className="logo-content">
                        <img src={logoActive} alt="logo" />
                    </div> */}
                    {/* {info && <div 
                        className="muse-video-player" 
                        data-video={info.video_id} 
                        // data-width="1200" 
                        data-height="600" 
                        data-links="0" 
                        data-logo="https://frogtutoring.com/images/logo.png"
                    />} */}
                    {info && <iframe src={`https://muse.ai/embed/${info.video_id}?start=20&volume=75`}  height="600" frameborder="0" allowfullscreen></iframe>}
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        isOpen: state.Modal.isVideoModalOpen,
        info: state.Modal.info,
    }
}
  
export default connect(mapStateToProps, { 
    toggleVideoModal 
})(withRouter(VideoModal));
