/**
 * Modal
 */
export const SERVICE_MODAL_TOGGLE            = 'SERVICE_MODAL_TOGGLE';
export const HOME_MODAL_TOGGLE            = 'HOME_MODAL_TOGGLE';
export const REQUEST_MODAL_TOGGLE            = 'REQUEST_MODAL_TOGGLE';

export const TUTOR_MODAL_TOGGLE               = 'TUTOR_MODAL_TOGGLE';
export const VIDEO_MODAL_TOGGLE               = 'VIDEO_MODAL_TOGGLE';
export const FEEDBACK_MODAL_TOGGLE               = 'FEEDBACK_MODAL_TOGGLE';
export const ENABLE_BOTTOM_MODAL               = 'ENABLE_BOTTOM_MODAL';

/**
 * Phone
 */
 export const GET_PHONE            = 'GET_PHONE';

/**
 * Location
 */
export const GET_LOCATION_DATA                  = 'GET_LOCATION_DATA';
export const GET_LOCATION_DATA_SUCCESS          = 'GET_LOCATION_DATA_SUCCESS';
export const GET_LOCATION_DATA_FAIL             = 'GET_LOCATION_DATA_FAIL';

/**
 * Landing
 */
 export const GET_LANDING1_DATA                  = 'GET_LANDING1_DATA';
 export const GET_LANDING1_DATA_SUCCESS          = 'GET_LANDING1_DATA_SUCCESS';
 export const GET_LANDING1_DATA_FAIL             = 'GET_LANDING1_DATA_FAIL';

/**
 * Review
 */
export const GET_ALL_REVIEWS                  = 'GET_ALL_REVIEWS';
export const GET_ALL_REVIEWS_SUCCESS          = 'GET_ALL_REVIEWS_SUCCESS';
export const GET_ALL_REVIEWS_FAIL             = 'GET_ALL_REVIEWS_FAIL';

/**
 * Request
 */
export const REQUEST_TUTOR                    = 'REQUEST_TUTOR';
export const REQUEST_TUTOR_SUCCESS            = 'REQUEST_TUTOR_SUCCESS';
export const REQUEST_TUTOR_FAIL               = 'REQUEST_TUTOR_FAIL';

/**
 * Call back
 */
export const CALL_BACK                    = 'CALL_BACK';
export const CALL_BACK_SUCCESS            = 'CALL_BACK_SUCCESS';
export const CALL_BACK_FAIL               = 'CALL_BACK_FAIL';


/**
 * Subject
 */
export const GET_SUBJECT                      = 'GET_SUBJECT';
export const GET_SUBJECT_SUCCESS              = 'GET_SUBJECT_SUCCESS';
export const GET_SUBJECT_FAIL                 = 'GET_SUBJECT_FAIL';

/**
 * Levels
 */
export const GET_LEVELS                         = 'GET_LEVELS';
export const GET_LEVELS_SUCCESS                 = 'GET_LEVELS_SUCCESS';
export const GET_LEVELS_FAIL                    = 'GET_LEVELS_FAIL';

/**
 * Pricing
 */
export const GET_PRICING                    = 'GET_PRICING';
export const GET_PRICING_SUCCESS            = 'GET_PRICING_SUCCESS';
export const GET_PRICING_FAIL               = 'GET_PRICING_FAIL';

/**
 * Pick up
 */
export const ZIP_CODE_AVAILBLE                    = 'ZIP_CODE_AVAILBLE';
export const ZIP_CODE_AVAILBLE_SUCCESS            = 'ZIP_CODE_AVAILBLE_SUCCESS';
export const ZIP_CODE_AVAILBLE_FAIL               = 'ZIP_CODE_AVAILBLE_FAIL';

export const REQUEST_PICKUP                    = 'REQUEST_PICKUP';
export const REQUEST_PICKUP_SUCCESS            = 'REQUEST_PICKUP_SUCCESS';
export const REQUEST_PICKUP_FAIL               = 'REQUEST_PICKUP_FAIL';

export const REQUEST_NEW_ZIP                    = 'REQUEST_NEW_ZIP';
export const REQUEST_NEW_ZIP_SUCCESS            = 'REQUEST_NEW_ZIP_SUCCESS';
export const REQUEST_NEW_ZIP_FAIL               = 'REQUEST_NEW_ZIP_FAIL';


