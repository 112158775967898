import React from 'react';
import './SchedulePage.scss';
import logoActive from '../../Assets/Icons/logo-inactive.png';
import play_icon from '../../Assets/Icons/play_icon.png';
import arrow from '../../Assets/Home/arrow.png';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// import { InlineWidget } from 'react-calendly';
// import { SCHEDULE_URL } from '../../Constant';
import { toggleVideoModal} from '../../Redux/Actions';
import Helper from '../../Utils/Helper';
import _ from 'lodash'

class SchedulePage extends React.Component {
    state = {
        
    }

    componentDidMount(){
        const script = document.createElement("script");
        script.text  = "gtag('event', 'conversion', {'send_to': 'AW-1011441392/lLhzCLjWiu8CEPC9peID'})";
        document.head.appendChild(script);
    };
    
    showVideo = (item) => {
        this.props.toggleVideoModal(true, item);
    }

    clickLogo(){
        //window.location.href = "https://frogtutoring.com/";
    }

    schedule(){
        window.location.href = "https://meetings.hubspot.com/region7/facebook-consultation";
    }

    render() {
        const { Landing1Data } = this.props;
        return (
            <div className="schedule-page">
                <div className="container v-r">
                    <div className="logo-area">
                        <div className="btn" onClick={()=>this.clickLogo()}><img src={logoActive} alt="logo" /></div>
                    </div>
                    <div className="main-content v-r">
                        <div className="col-lg-7">
                            <h1>Thank you for reaching out!</h1>
                            <div className="description">Expect a call from our very friendly student success advocate shortly or you can schedule a time that works best for you by clicking the schedule free consultation button below.</div>
                        </div>
                        <div className="row">
                            <div className="col-lg-7 left-col">
                                <div className="arrow show-web" >
                                        <img src={arrow} alt="arrow" />
                                </div>
                                {/* <div className="calendar-content show-web">
                                    <InlineWidget styles={{ minWidth:'320px',height:'650px', marginBottom:'10px' }} url={SCHEDULE_URL} />
                                </div>
                                <div className="calendar-content show-mobile">
                                    <InlineWidget styles={{ minWidth:'320px',height:'450px', marginBottom:'10px' }} url={SCHEDULE_URL} />
                                </div> */}
                                <div className="schedule-btn v-c h-c" onClick={()=>this.schedule()}>
                                    Schedule Free Consultation
                                </div>
                            </div>
                            <div className="col-lg-5 right-col">
                                
                            </div>
                            <div className="video-container">
                                <div className="video-title mt-4 mb-3">Why our parents & students love us</div>
                                <div className="row">
                                    {!_.isEmpty(Landing1Data) && Landing1Data.video_reviews.map((item, index)=> (
                                        <div key={index} className="video-item mb-5 col-lg-4" onClick={()=>this.showVideo(item)}>
                                            <div className="video-header">
                                                <img src={item.image} alt="avatar" className="avatar mb-2" />
                                            </div>
                                            <div className="play-icon-container">
                                                <img src={play_icon} alt="play-icon" className="play-icon" />
                                            </div>
                                            <p>{item.name}</p>      
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
        Landing1Data: state.Landing.Landing1Data,
    }
}

export default connect(mapStateToProps, { 
    toggleVideoModal
})(withRouter(SchedulePage));