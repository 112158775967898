import React from 'react';
import './InputBox.scss';
import { ReactSVG } from 'react-svg';
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/scss/react-flags-select.scss';

export default class InputBox extends React.Component {
    state = {
        value: this.props.value || '',
        icon: this.props.icon || '',
        placeholder: this.props.placeholder || '',
        type: this.props.type || 'text',
        focused: false,
        show_forgot: this.props.show_forgot || false,
        phone_input: this.props.phone_input || false,
        country: this.props.country || '',
    }

    onChange = (e) => {
        this.setState({ value: e.target.value });
        this.props.onChange(e.target.value);
    }

    onSelectFlag = (val) => {
        this.setState({ country: val });
        this.props.onSelect(val);
    }

    render() {
        const { value, icon, placeholder, type, focused, phone_input, country } = this.state;
        const { className, requestInfo } = this.props;
        return (
            <div className={`inputbox-component v-c round-object ${className} `}>
                {phone_input && (
                    <ReactFlagsSelect
                        className="custom-flag"
                        defaultCountry={country}
                        onSelect={val => this.onSelectFlag(val)}
                        searchable={true}
                        showSelectedLabel={false}
                    />
                )}
                {(icon && !phone_input) && <ReactSVG src={icon} className="svg-icon" />}
                <div className="input-area v-r">
                    {(focused || value) && <p className="upon-placeholder">{placeholder}</p>}
                    <input className={`custom-input ${requestInfo ? "request-input" : ""}`} type={type} placeholder={focused ? '' : placeholder} value={value}
                        onChange={e => this.onChange(e)}
                        onFocus={e => this.setState({ focused: true })}
                        onBlur={e => this.setState({ focused: false })}
                    />
                </div>
            </div>
        )
    };
};
