import React from 'react';
import './Services.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import services1 from '../../../Assets/Home/Services/services1.png';
import services2 from '../../../Assets/Home/Services/services2.png';
import services3 from '../../../Assets/Home/Services/services3.png';
import services4 from '../../../Assets/Home/Services/services4.png';
import services5 from '../../../Assets/Home/Services/services5.png';
import services_mobile from '../../../Assets/Home/Services/services_mobile.png';
import k_12 from '../../../Assets/Home/Services/k_12.png';
import college from '../../../Assets/Home/Services/college.png';
import test_prep from '../../../Assets/Home/Services/test_prep.png';
import professional_course from '../../../Assets/Home/Services/professional_course.png';
import language from '../../../Assets/Home/Services/language.png';

import up_icon from '../../../Assets/Icons/up_icon.png';
import down_icon from '../../../Assets/Icons/down_icon_white.png';

const Category = [
    {icon: k_12, title: 'K-12 Tutoring', image: services1, description: 'We match you with a high performing 1-on-1 tutor with a track record of success backed by our 100% money back guarantee.', isSelect: true},
    {icon: college, title: 'College Tutoring', image: services2, description: 'We match you with a high performing 1-on-1 tutor with a track record of success backed by our 100% money back guarantee.', isSelect: false},
    {icon: test_prep, title: 'Test Prep Tutoring', image: services3, description: 'We match you with a high performing 1-on-1 tutor with a track record of success backed by our 100% money back guarantee.', isSelect: false},
    {icon: professional_course, title: 'Professional Course tutoring', image: services4, description: 'We match you with a high performing 1-on-1 tutor with a track record of success backed by our 100% money back guarantee.', isSelect: false},
    {icon: language, title: 'Language Tutoring', image: services5, description: 'We match you with a high performing 1-on-1 tutor with a track record of success backed by our 100% money back guarantee.', isSelect: false},
]

class Services extends React.Component {

    state ={
        select_index: 0,
        Category: Category
    }

    selectCategory(item, index){
        Category[index].isSelect = !this.state.Category[index].isSelect
        this.setState({Category})
        this.setState({select_index: index})
    }

    render() {
        const { select_index, Category } = this.state;

        return (
            <div className="services-component">
                <div className="container">
                    <h1 className="text-center">Services</h1>
                    <div className="row">
                        <div className="col-lg-6">
                            {Category.map((item, index)=> (
                                <div key={index}>
                                    <div className={`category-item shadow-object show-web ${ index === select_index? "category-active" : ""}`}  onClick={()=>this.selectCategory(item, index)}>
                                        <div className="v-c">
                                            <div className="logo-container v-c h-c mr-3">
                                                <img src={item.icon} alt="brand" className="" />
                                            </div>
                                            <div className="item-description">{item.title}</div>
                                        </div>
                                    </div>
                                    <div className={`category-item shadow-object show-mobile ${ item.isSelect ? "category-active" : ""}`}  onClick={()=>this.selectCategory(item, index)}>
                                        <div className="v-c">
                                            <div className="logo-container v-c h-c mr-3">
                                                <img src={item.icon} alt="logo" className="logo" />
                                            </div>
                                            <div className="item-description">{item.title}</div>
                                        </div>
                                        <div className="show-mobile">
                                            {item.isSelect ? 
                                                <img src={up_icon} alt="brand" className="" />
                                                :
                                                <img src={down_icon} alt="brand" className="" />
                                            }
                                        </div>
                                    </div>
                                    <div className={`show-mobile ${ item.isSelect ? "detail-active" : "detail-inActive"}`}>
                                        <img src={item.image} alt="brand" className="right-brand" />
                                        <p className="text-center mt-3 mb-3">{item.description}</p>
                                    </div>
                                </div>
                            ))}                            
                        </div>
                        <div className="col-lg-6 show-web">
                            <div className="right-area">
                                <img src={Category[select_index].image} alt="brand" className="" />
                                <p className="text-center mt-3">{Category[select_index].description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, {})(withRouter(Services));
