import React from 'react';
import './WelcomePage.scss';
import logoActive from '../../Assets/Icons/logo-inactive.png';
import Step6 from '../../Assets/Request/Step6.png';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Helper from '../../Utils/Helper';
import { getLanding1Data } from '../../Redux/Actions';
import _ from 'lodash'

class WelcomePage extends React.Component {

    state ={
        subject: "Math"
    }

    componentDidMount (){
        if(this.props.Landing1Data.subject !== "private"){
            this.setState({subject: this.props.Landing1Data.subject})
        }
    }

    // componentDidUpdate(prevProps) {
    //     if (prevProps.get_landing1_data_loading && !this.props.get_landing1_data_loading) {
    //         if(this.props.get_landing1_data_success){
    //             Helper.hideSpinner();
    //             if(this.props.Landing1Data.subject !== "private"){
    //                 this.setState({subject: this.props.Landing1Data.subject})
    //             }
    //         }
    //     }
    // }

    clickLogo(){
        //window.location.href = "https://frogtutoring.com/";
    }

    showMe(){
        window.location.href = `https://frogtutoring.com/tutors/${this.state.subject}_Tutors`;
    }

    render() {
        
        return (
            <div className="welcome-page">
                <div className="container v-r">
                    <div className="logo-area">
                        <div className="btn" onClick={()=>this.clickLogo()}><img src={logoActive} alt="logo" /></div>
                    </div>
                    <div className="main-content v-r">
                        <div className="row">
                            <div className="col-lg-5 left-col">
                                <div>
                                    <img src={Step6} alt="ads" className="ads-img" />
                                </div>
                            </div>
                            <div className="col-lg-7 right-col">
                                <h1>Thank you for scheduling a call</h1>
                                <div className="description">While you wait to hear from our student success advocate, you can review profiles of tutors</div>
                                <div className="show-me-btn btn v-c h-c mt-4 mb-4" onClick={()=>this.showMe()}>SHOW ME PROFILES OF TUTORS</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
        Landing1Data: state.Landing.Landing1Data,
        // get_landing1_data_loading: state.Landing.get_landing1_data_loading,
        // get_landing1_data_success: state.Landing.get_landing1_data_success,
    }
}

export default connect(mapStateToProps, { 
    // getLanding1Data
})(withRouter(WelcomePage));