import React from 'react';
import './Media.scss';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import dot from '../../../Assets/Icons/dot.png';
import play_icon from '../../../Assets/Icons/play_icon.png';
import { toggleVideoModal } from '../../../Redux/Actions';

class Media extends React.Component {
    state ={
        current_media: '',
        current_index: 0
    }

    componentDidMount(){
        this.setState({current_media: this.props.media[0]})
    }

    selectBrand(item, index){
        this.setState({
            current_media: item,
            current_index: index
        });
    }

    showVideo = () => {
        this.props.toggleVideoModal(true, this.state.current_media);
    }

    render() {
        const { media } = this.props;
        const { current_index, current_media } = this.state;
        return (
            <div className="media-component">
                <div className="container">
                    <h1 className="text-center">Featured on local and national media</h1>
                    <div className="row main-row">
                        <div className="col-lg-6 left-container v-c h-c">
                            <div className="left-area shadow-object">
                                <img src={dot} alt="dot" className="dot" />
                                <p>{current_media.title}</p>
                                <div className="v-c mt-4">
                                    <div className="logo-content v-c h-c">
                                        <img src={current_media.image} alt="brand" className="brand" />
                                    </div>
                                    <div className="ml-3">
                                        <div>{current_media.media_name}</div>
                                        <div>Our partner</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6  right-container v-c-h-c">
                            <div className="right-area shadow-object" onClick={()=>this.showVideo()}>
                                <img src={current_media.preview} alt="video" className="video" />                    

                                <img src={play_icon} alt="play-icon" className="play-icon" />                    
                            </div>
                        </div>
                    </div>
                    <div className="logo-list">
                        {media.map((item, index)=> (
                            <div key={index} className={`${current_index === index? "brand-container" : ""}`} onClick={()=>this.selectBrand(item, index)}>
                                <div  className="brand-content v-c h-c">
                                    <img src={item.image} alt="brand" className="brand" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, {
    toggleVideoModal
})(withRouter(Media));
