import { GET_LANDING1_DATA } from '../Type';

const value ={
  loc_interest_ms: '',
  loc_physical_ms: '',
  subject: '',
  phone: '',
  city: '',
  keyword: '',
  campaign: '',
  gclid: ''
}

export const getLanding1Data = (deploy = value) => {
  return {
    type: GET_LANDING1_DATA,
    data: deploy
  }
}