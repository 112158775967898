import React from 'react';
import './AboutUs.scss';
import Media from 'react-media';
import Loadable from '@loadable/component';
import { CarouselLeftButton, CarouselRightButton } from '../../../Constant';

import { Feedback } from '../../';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllReviews } from '../../../Redux/Actions';
import _ from 'lodash'

const OwlCarousel = Loadable(() => import('react-owl-carousel'));


class AboutUs extends React.Component {

    render() {        
        const { video_reviews } = this.props;
        return (
            <div className="aboutus-component">
                <div className="container">
                    <h1 className="text-center">What parents & students are saying about us</h1>
                    <div className="">
                        {!_.isEmpty(video_reviews) && <Media queries={{
                            lg: "(min-width: 992px)",
                            md: "(min-width: 768px)",
                            }}>
                            {matches => (
                                <OwlCarousel
                                    className="owl-theme"
                                    rewind
                                    nav
                                    stagePadding={5}
                                    margin={15}
                                    items={ matches.lg ? 3 : matches.md ? 2 : 1}
                                    dots={false}
                                    loop={false}
                                    navText={[CarouselLeftButton, CarouselRightButton]}
                                    >
                                    { video_reviews.map((item, index) => <Feedback info={item} index={index} key={index}/>)}
                                </OwlCarousel>
                            )}
                        </Media>}
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
          
    }
}

export default connect(mapStateToProps, {getAllReviews})(withRouter(AboutUs));
