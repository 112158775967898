import React from 'react';
import './HomeModal.scss';
import { Modal } from 'react-bootstrap';
import { toggleHomeModal } from '../../Redux/Actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import add from '../../Assets/Icons/add2.svg';

import left_logo from '../../Assets/Home/left_logo.png';
import phone_icon from '../../Assets/Icons/phone.png';

import { ReactSVG } from 'react-svg'
import { InputBox, } from '../';
import { callback } from '../../Redux/Actions';
import Helper from '../../Utils/Helper';

class HomeModal extends React.Component {
    state = {
        isOpen: true,
        info: '',
        phone: '',
        country: 'US',

    }

    componentDidUpdate(prevProps) {
        if (prevProps.callback_loading && !this.props.callback_loading) {
            Helper.hideSpinner();
            if(this.props.callback_success){
                Helper.showToast('success', 3000, "Thank you for your request!")
                this.closeModal();
                // this.props.history.push('/request-tutor/schedule')
                window.location.href = "https://hellofrogtutoring.com/request-tutor-schedule/";
            } else {
                Helper.showToast('error', 3000, this.props.callback_message)
            }
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            isOpen: props.isOpen,
            info: props.info,
        }
    }

    closeModal = () => {
        sessionStorage.setItem("doneHomeModal", true);
        this.props.toggleHomeModal(false);
    }

    onHide = () => {
    }

    request(){
        if(this.state.phone){
            Helper.showSpinner();
            const { info } = this.state
            const formData = new FormData();		
            formData.append(`data[phone]`, this.state.phone);
            formData.append(`data[region]`, info.city);
            formData.append(`data[subject]`, info.subject);
            this.props.callback(formData);
        }
    }

    render() {
        let { isOpen, country } = this.state;
        const {info} = this.props
        return (
            <Modal show={isOpen} onHide={this.onHide} className="home-modal-component">
                <div className="home-content v-r">                    
                    <div className="close-btn"> 
                        <ReactSVG src={ add } className='icon-close' onClick={ () => this.closeModal() }/> 
                    </div>
                    
                    <div className="row main-row">
                        <div className="col-lg-5 show-web left-area">
                            <img src={left_logo} alt="logo" />
                        </div>
                        <div className="col-lg-7 right-area">
                            <h1>Our student success advocate would like to match you with the perfect tutor</h1>
                            <div className="mt-4 sub-description show-web">Give us a <strong className="strong-call">CALL Now!</strong></div>
                            <div className="mt-4 mb-3 sub-description show-mobile">Give us a <strong className="strong-call">CALL Now!</strong></div>
                            <a href={`tel:+1${info? info.phone : ''}`} className="v-c mt-4 mb-5 show-web-flex phone-container">
                                <img src={phone_icon} alt="phone" className="phone mr-3" />
                                <div className="phone">{info? info.phone : ''}</div>
                            </a>
                            <div className="line"></div>
                            <div className="or v-c h-c">or</div>
                            <div className="call-back pt-4 pb-4 sub-description">Request a callback</div>
                            <div className="phone-input-content">
                                <InputBox className="input-box" phone_input country={this.state.country} onSelect={e => this.onChange(country, e)} requestInfo={true} type="number" placeholder="Phone number" value={this.state.phone} onChange={e => this.setState({phone: e})} />
                                <div className="request-btn" onClick={()=>this.request()}>REQUEST CALLBACK</div>
                            </div>

                            <div className="helpful mt-4 show-web">They are very sweet and helpful! You will love them.</div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        isOpen: state.Modal.isHomeModalOpen,
        info: state.Modal.info,

        callback_loading: state.Request.callback_loading,
        callback_success: state.Request.callback_success,
        callback_message: state.Request.callback_message,
    }
}
  
export default connect(mapStateToProps, { 
    toggleHomeModal,
    callback,
})(withRouter(HomeModal));
