import React from 'react';
import './LandingPage3.scss';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { 
  LandingStart,
  Brand,
  WhyUs,
  ReviewsArea,
  AboutUs,
  Media,
  Universities,
  Tutors,
  Services,
  RequestTutor,
} from '../../Components';
import { toggleRequestModal, toggleHomeModal, getPhoneNumber, getLanding1Data } from '../../Redux/Actions';
import Helper from '../../Utils/Helper';
import _ from 'lodash'
const publicIp = require('public-ip');

class LandingPage3 extends React.Component {

  componentDidMount (){
    if (this.props.location.search && this.props.location.search.length > 0) {
      const params = new URLSearchParams(this.props.location.search);
      const loc_interest_ms = params.get('loc_interest_ms');
      const loc_physical_ms = params.get('loc_physical_ms');
      const subject = params.get('subject');
      const phone = params.get('phone');
      const city = params.get('city');
      const keyword = params.get('keyword');
      const campaign = params.get('campaign');
      const gclid = params.get('gclid');
      const deploy ={
        loc_interest_ms,
        loc_physical_ms,
        subject,
        phone,
        city,
        keyword,
        campaign,
        gclid
      }      
      this.props.getLanding1Data(deploy);
    } else {      
      this.props.getLanding1Data();
    }

    // Helper.showSpinner();

    const doneHomeModal = sessionStorage.getItem("doneHomeModal");
    if(!doneHomeModal){
      this.timer =  setTimeout(function() {
        this.props.toggleHomeModal(true, this.props.Landing1Data);
      }.bind(this), 90*1000)
    }
  }

  componentWillUnmount(){
    clearTimeout(this.timer)
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.get_landing1_data_loading && !this.props.get_landing1_data_loading) {
      if(this.props.get_landing1_data_success){
        Helper.hideSpinner();
        var deploy ={
          phone: this.props.Landing1Data.phone,
          city: this.props.Landing1Data.city
        }
        this.props.getPhoneNumber(deploy);
      }
    }
  }

  render() {
    const { Landing1Data } = this.props;
    return (
      <>
        <div className="landing-page">
          {Landing1Data && <LandingStart city={Landing1Data.city} landing3={true}/>}
          <Brand />
          <WhyUs />
          {Landing1Data && <ReviewsArea reviews={Landing1Data.reviews}/>}
          {Landing1Data && <AboutUs video_reviews={Landing1Data.video_reviews}/>}
          {Landing1Data && <Media media={Landing1Data.media}/>}
          <Universities />
          {Landing1Data && <Tutors tutors={Landing1Data.tutors}/>}
          <Services />
          {Landing1Data &&<RequestTutor phoneNumber={Landing1Data.phone} city={Landing1Data.city}/>}
        </div>
      </>
    )
  };
};

function mapStateToProps(state) {
  return {
    Landing1Data: state.Landing.Landing1Data,
    get_landing1_data_loading: state.Landing.get_landing1_data_loading,
    get_landing1_data_success: state.Landing.get_landing1_data_success,
  }
}

export default connect(mapStateToProps, {
  toggleRequestModal,
  toggleHomeModal,
  getPhoneNumber,
  getLanding1Data
})(withRouter(LandingPage3));