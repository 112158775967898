import { GET_LANDING1_DATA, GET_LANDING1_DATA_SUCCESS, GET_LANDING1_DATA_FAIL } from '../Type';
const default_landing_data = {
  city : "",
  phone: "(817) 840-7732",
  subject: "private",
  media: [
    {
      image: "https://i.imgur.com/u4rBehg.png",
      media_name: "KVUE-TV",
      preview: "https://i.imgur.com/EyeDWJM.png",
      title: "Frog Tutoring in the news",
      video_id: "ppgwNdo"
    }
  ],
  reviews: [],
  tutors: [],
  video_reviews: []
}

const INITIAL = {
  Landing1Data: default_landing_data,
  get_landing1_data_loading: false,
  get_landing1_data_success: false
}

export default (state = INITIAL, action) => {
  switch (action.type) {
    case GET_LANDING1_DATA: {
      return {
        Landing1Data: default_landing_data,
        get_landing1_data_loading: true,
        get_landing1_data_success: false,
      }
    }
    case GET_LANDING1_DATA_FAIL: {
        return {
          Landing1Data: null,
          get_landing1_data_loading: false,
          get_landing1_data_success: false
        }
    }
    case GET_LANDING1_DATA_SUCCESS: {
      return {
        Landing1Data: action.data,
        get_landing1_data_loading: false,
        get_landing1_data_success: true
      }
    }

    default:
        return state;
  }
}