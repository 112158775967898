import React from 'react';
import './Brand.scss';
import CNN from '../../../Assets/Brand/CNN.png';
import CNN_mobile from '../../../Assets/Brand/CNN_mobile.png';
import NBC_NEWS from '../../../Assets/Brand/NBC_NEWS.png';
import NBC_NEWS_mobile from '../../../Assets/Brand/NBC_NEWS_mobile.png';
import NEWS from '../../../Assets/Brand/NEWS.png';
import NEWS_mobile from '../../../Assets/Brand/NEWS_mobile.png';
import ESSENCE from '../../../Assets/Brand/ESSENCE.png';
import ESSENCE_mobile from '../../../Assets/Brand/ESSENCE_mobile.png';
import FOX from '../../../Assets/Brand/FOX.png';
import FOX_mobile from '../../../Assets/Brand/FOX_mobile.png';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class Brand extends React.Component {

    render() {
        const { city } = this.props;
        return (
            <div className="brand-component">
                <div className="container">
                    <div className="main-content">
                        <div className="v-c h-c">
                            <img src={CNN} alt="brand" className="show-web" />
                            <img src={CNN_mobile} alt="brand" className="show-mobile" />
                        </div>
                        <div className="v-c h-c">
                            <img src={NBC_NEWS} alt="brand" className="show-web" />
                            <img src={NBC_NEWS_mobile} alt="brand" className="show-mobile" />
                        </div>
                        <div className="v-c h-c">
                            <img src={NEWS} alt="brand" className="show-web" />
                            <img src={NEWS_mobile} alt="brand" className="show-mobile" />
                        </div>
                        <div className="v-c h-c">
                            <img src={ESSENCE} alt="brand" className="show-web" />
                            <img src={ESSENCE_mobile} alt="brand" className="show-mobile" />
                        </div>
                        <div className="v-c h-c">
                            <img src={FOX} alt="brand" className="show-web" />                
                            <img src={FOX_mobile} alt="brand" className="show-mobile" />                
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, {})(withRouter(Brand));
