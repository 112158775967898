import { 
  REQUEST_TUTOR, REQUEST_TUTOR_FAIL, REQUEST_TUTOR_SUCCESS,
  CALL_BACK, CALL_BACK_FAIL, CALL_BACK_SUCCESS,
 } from '../Type';

const INITIAL = {
  request_message: '',
  request_loading: false,
  request_success: false,

  callback_message: '',
  callback_loading: false,
  callback_success: false
}

export default (state = INITIAL, action) => {
  switch (action.type) {
    case REQUEST_TUTOR: {
      return {
        request_loading: true,
        request_success: false,
      }
    }
    case REQUEST_TUTOR_FAIL: {
        return {
          request_message: action.data.msg,
          request_loading: false,
          request_success: false
        }
    }
    case REQUEST_TUTOR_SUCCESS: {
      return {
        // request_message: action.data,
        request_loading: false,
        request_success: true
      }
    }

    case CALL_BACK: {
      return {
        callback_loading: true,
        callback_success: false,
      }
    }
    case CALL_BACK_FAIL: {
        return {
          callback_message: action.data.msg,
          callback_loading: false,
          callback_success: false
        }
    }
    case CALL_BACK_SUCCESS: {
      return {
        // callback_message: action.data,
        callback_loading: false,
        callback_success: true
      }
    }

    default:
        return state;
  }
}