import { GET_SUBJECT, GET_SUBJECT_SUCCESS, GET_SUBJECT_FAIL } from '../Type';

const INITIAL = {
    subject_list: [],
    get_subject_success: false,
    get_subject_loading: false,
}

export default (state = INITIAL, action) => {
    switch (action.type) {
        case GET_SUBJECT: {
            return {
                ...state,
                get_subject_success: false,
                get_subject_loading: true,
            }
        }
        case GET_SUBJECT_SUCCESS: {
            return {
                ...state,
                get_subject_success: true,
                subject_list: action.data, 
                get_subject_loading: false,
            }
        }
        case GET_SUBJECT_FAIL: {
            return {
                ...state,
                get_subject_success: false,
                get_subject_loading: false
            }
        }
        default:
            return state;
    }
}