import React from 'react';
import './Footer.scss';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import phone_call from '../../Assets/Icons/phone-call.png';
import { getPhoneNumber } from '../../Redux/Actions';

class Footer extends React.Component {
    state ={
        
    }

    static getDerivedStateFromProps(props, state) {
        return {
            data: props.data.data,
        }
    }

    hideFooter = () => {
        const pathName = this.props.location.pathname;
        return (
            pathName === '/request-tutor' ||
            pathName === '/request-tutor/schedule' ||
            pathName === '/request-tutor/welcome' 
        ) 
    }

    request(){
        this.props.history.push({
            pathname: '/request-tutor',
            request_category: "normal"
        });
    }

    
    render() {
        const { data } = this.props;
        return (
            <div className="footer-component" style={{display: this.hideFooter() && 'none'}}>
                <div className="v-c h-c main-footer">
                    <div className="footer-text">©2021 Frogtutoring. All rights reserved</div>
                </div>                   
                <div className="container show-mobile phone-call-area">
                    <div className="request-btn v-c h-c mt-3" onClick={()=>this.request()}>Request tutoring info</div>
                    <a href={`tel:+1${data.phone}`} className="call-btn v-c h-c mt-3 mb-3">
                        <img src={phone_call} alt="phone" className="mr-3"/>
                        <div>CALL NOW</div>
                    </a>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
        data: state.Phone.data,
    }
}

export default connect(mapStateToProps, {getPhoneNumber})(withRouter(Footer));
