import { Api } from './Api';

export function* requestTutor(data){
    const jsonData = yield Api.formPost("/alerts/newlanding", data);
    return jsonData;
}

export function* callback(data){
    const jsonData = yield Api.formPost("/frogtutoring/requestcallback", data);
    return jsonData;
}