import {
    REQUEST_TUTOR_SUCCESS, REQUEST_TUTOR_FAIL, REQUEST_TUTOR,
    CALL_BACK_SUCCESS, CALL_BACK_FAIL, CALL_BACK,
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
    requestTutor as requestTutorApi,
    callback as callbackApi,
} from '../../Api';
import _ from 'lodash'

function* requestTutor(payload){
    try {
        const result = yield requestTutorApi(payload.data)
        if (_.isEmpty(result.errors)) {
            yield put({ type: REQUEST_TUTOR_SUCCESS, data: result.data })
        } else {
            let errors = result.errors[0]
            
            yield put({ type: REQUEST_TUTOR_FAIL, data: {msg: errors} })
        }
    } catch (err) {
        yield put({ type: REQUEST_TUTOR_FAIL, data: [] })
    }
}

export function* watchRequestTutor(){
    yield takeLatest(REQUEST_TUTOR, requestTutor)
}

function* callback(payload){
    try {
        const result = yield callbackApi(payload.data)
        if (result) {
            yield put({ type: CALL_BACK_SUCCESS, data: result.data })
        } else {
            let errors = result.errors[0]
            
            yield put({ type: CALL_BACK_FAIL, data: {msg: errors} })
        }
    } catch (err) {
        yield put({ type: CALL_BACK_FAIL, data: [] })
    }
}

export function* watchCallback(){
    yield takeLatest(CALL_BACK, callback)
}