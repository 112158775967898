import React from 'react';
import './ReviewsArea.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Media from 'react-media';
import Loadable from '@loadable/component';
import { CarouselLeftButton, CarouselRightButton } from '../../../Constant';
import { Review } from '../../';
import arrow from '../../../Assets/Home/arrow.png';

const OwlCarousel = Loadable(() => import('react-owl-carousel'));

class ReviewsArea extends React.Component {
    state = {
        show_list: []
    }

    request(){
        this.props.history.push({
            pathname: '/request-tutor',
            request_category: "normal"
        });
    }

    render() {
        const { reviews } = this.props;
        return (
            <div className="reviewsarea-component">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 v-r sub-col">
                            <div className="arrow show-web" >
                                <img src={arrow} alt="arrow" />
                            </div>
                            <div className="">
                                <h1>Our parents & students love us</h1>
                                <div className="btn-request v-c h-c btn" onClick={()=>this.request()}>REQUEST TUTORING INFO</div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <Media queries={{
                                ex: "(min-width: 1200px)",
                                lg: "(min-width: 992px)",
                                md: "(min-width: 768px)",
                                }}>
                                {matches => (
                                    <OwlCarousel
                                        className="owl-theme"
                                        rewind
                                        nav
                                        stagePadding={0}
                                        margin={matches.ex ? 30 : 15}
                                        items={ matches.lg ? 2 : 1}
                                        dots={false}
                                        loop={false}
                                        navText={[CarouselLeftButton, CarouselRightButton]}
                                        >
                                        { reviews.map((item, index) => <Review info={item} index={index} key={index} />)}
                                    </OwlCarousel>
                                )}
                            </Media>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
        review_list: state.Review.review_list
    }
}

export default connect(mapStateToProps, { 
    // getAllReviews
 })(withRouter(ReviewsArea));