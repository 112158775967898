import React from 'react';
import './ServiceModal.scss';
import { Modal } from 'react-bootstrap';
import { toggleServiceModal } from '../../Redux/Actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import close_icon from '../../Assets/Icons/close_icon.svg';
import close_icon_mobile from '../../Assets/Icons/close_icon_mobile.svg';

import { ReactSVG } from 'react-svg'
import { Feedback } from '../';

function innerFunc(item) {
    return {__html: item}
}

class ServiceModal extends React.Component {
    state = {
        isOpen: false,
        info: ''
    }

    static getDerivedStateFromProps(props, state) {
        return {
            isOpen: props.isOpen,
            info: props.info
        }
    }

    closeModal = () => {
        this.props.toggleServiceModal(false);
    }

    onHide = () => {
    }

    render() {
        let { isOpen, info } = this.state;
        return (
            <Modal show={isOpen} onHide={this.onHide} className="service-modal-component">
                {info && <div className="service-content v-r">
                    <div className="close-btn" onClick={ () => this.closeModal() }> 
                        <ReactSVG src={ close_icon } className='icon-close show-web'/>
                        <ReactSVG src={ close_icon_mobile } className='icon-close show-mobile'/>
                    </div>
                    <div className="title">{info.title}</div>
                    {/* <div className="detail">{info.more}</div> */}
                    <div className="detail" dangerouslySetInnerHTML={innerFunc(info.more)}/>
                </div>}
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        isOpen: state.Modal.isServiceModalOpen,
        info: state.Modal.info,
    }
}
  
export default connect(mapStateToProps, { toggleServiceModal })(withRouter(ServiceModal));
