import React from 'react';
import './Universities.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import university_list from '../../../Assets/Home/University/university_list.png';

import HOWARD from '../../../Assets/Home/University/HOWARD.png';
import second from '../../../Assets/Home/University/second.png';
import Standford from '../../../Assets/Home/University/Standford.png';
import Berkeley from '../../../Assets/Home/University/Berkeley.png';
import USC from '../../../Assets/Home/University/USC.png';
import CALTECH from '../../../Assets/Home/University/CALTECH.png';
import TCU from '../../../Assets/Home/University/TCU.png';
import TEXAS from '../../../Assets/Home/University/TEXAS.png';
import COLUMBIA from '../../../Assets/Home/University/COLUMBIA.png';
import EMORY from '../../../Assets/Home/University/EMORY.png';
import HARVARD from '../../../Assets/Home/University/HARVARD.png';
import Yale from '../../../Assets/Home/University/Yale.png';


const University =[
    {logo: HOWARD},
    {logo: second},
    {logo: Standford},
    {logo: Berkeley},
    {logo: USC},
    {logo: CALTECH},
    {logo: TCU},
    {logo: TEXAS},
    {logo: COLUMBIA},
    {logo: EMORY},
    {logo: HARVARD},
    {logo: Yale},
]


class Universities extends React.Component {

    render() {
        return (
            <div className="universities-component">
                <div className="container">
                    <h1 className="text-center">Tutors are students and graduates from top US universities</h1>
                    <div className="row">
                        <div className="show-web">
                            <img src={university_list} alt="university-list" className="university-list" />                        
                        </div>
                        <div className="university-list show-mobile">
                            {University.map((item, index)=> (
                                <div key={index}>
                                    <img src={item.logo} alt="university-logo" className="university-logo" />                        
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, {})(withRouter(Universities));
