import { all, fork } from 'redux-saga/effects';

import { watchGetLocatonData } from './Location';
import { watchGetLevels } from './Levels';
import { watchGetLanding1Data } from './Landing';
import { watchGetAllReviews } from './Review';
import { watchRequestTutor, watchCallback } from './Request';
import { watchGetSubject } from './Subject';
import { watchGetPricing } from './Pricing';
import { watchZipcodeavailable, watchRequestpickup, watchRequestNewZip } from './Pickup';


export default function* rootSaga() {
    yield all([
        fork(watchGetLocatonData),

        fork(watchGetLevels),


        fork(watchGetLanding1Data),
        
        fork(watchGetAllReviews),
        fork(watchRequestTutor),
        fork(watchCallback),        

        fork(watchGetSubject),

        fork(watchGetPricing),

        fork(watchZipcodeavailable),
        fork(watchRequestpickup),
        fork(watchRequestNewZip),       
    ]);
}