import React from 'react';
import './LandingStart.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import start_img from '../../../Assets/Home/start_img.png';
import start_img_mobile from '../../../Assets/Home/start_img_mobile.png';

class LandingStart extends React.Component {
    state = {

    }

    yes(){
        if(this.props.landing3){
            this.props.history.push({
                pathname: '/request-tutor',
                request_category: "normal"
            });
        } else {
            this.props.history.push({
                pathname: '/request-tutor',
                request_category: "yes",
                subject: this.props.subject
            });
        }
    }

    no(){
        this.props.history.push({
            pathname: '/request-tutor',
            request_category: "no"
        });
    }

    render() {
        const { city, subject, landing1, landing2, landing3 } = this.props;
        return (
            <div className="landing1start-component">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 v-c h-c show-web order-lg-first order-second">
                            <img src={start_img} alt="start-img" className="start-img" />
                        </div>
                        <div className="col-lg-6 left-area show-mobile">
                            <img src={start_img_mobile} alt="start-img" className="start-img" />
                        </div>
                        <div className="col-lg-6 right-area order-lg-second order-first">
                            {landing1 && <h1>Just to confirm, you're looking for a <strong className="strong-title">{`${subject} tutor`}</strong> in <strong className="strong-title">{city}</strong> or a Nearby City?</h1>}
                            {landing2 && <h1>Just to confirm, you're looking for an online <strong className="strong-title">{`${subject} tutor`}</strong>?</h1>}
                            {landing3 && <h1>Just to confirm, you're looking for a <strong className="strong-title">private tutor </strong> in <strong className="strong-title">{city}</strong> or a Nearby City?</h1>}
                            <div className="v-c mt-5 btn-container">                                
                                <div className="shadow-object box-item yes-btn v-c h-c btn mr-4" onClick={()=>this.yes()}>YES</div>
                                <div className="shadow-object box-item no-btn v-c h-c btn" onClick={()=>this.no()}>No</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, {})(withRouter(LandingStart));
