import React from 'react';
import './style.scss';
import rightArrow from '../../Assets/Icon/arrow-right.png';

export default function SelectBtn({
    className = '', item, selectedId, onClick,
}) {

    return (
        <div
            className={
                `select-btn 
          ${className}
          ${item.id === selectedId ? 'active' : ''}
        `
            }
            onClick={() => onClick(item)}>
            <div className='title'>{item.label}</div>
            <img src={rightArrow} alt='arrow' className='arrow-btn' />
        </div >
    );
}