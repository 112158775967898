
import { 
   ZIP_CODE_AVAILBLE, ZIP_CODE_AVAILBLE_FAIL, ZIP_CODE_AVAILBLE_SUCCESS, 
   REQUEST_PICKUP, REQUEST_PICKUP_FAIL, REQUEST_PICKUP_SUCCESS, 
   REQUEST_NEW_ZIP, REQUEST_NEW_ZIP_FAIL, REQUEST_NEW_ZIP_SUCCESS, 
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
  zipcodeavailable as zipcodeavailableApi,
  requestpickup as requestpickupApi,
  requestNewZip as requestNewZipApi,
} from '../../Api';

function* zipcodeavailable(payload) {
    try {
        const result = yield zipcodeavailableApi(payload.zipcode);

        if (result && result.available) {
            yield put({ type: ZIP_CODE_AVAILBLE_SUCCESS, data: {msg: result.message}})
        } else {
            let message = "Sorry! We're not currently offering free pick-up and delivery in your area. Please enter your email below for use to contact you once the service becomes available in your zipcode."
            // let message = result.message;
            // let errors = result.errors || {};
            // for (let item of Object.keys(errors)) {
            //     let error_part = errors[item];
            //     for (let msg of error_part) {
            //         message += ' ' + msg;
            //     }
            // }
            yield put({ type: ZIP_CODE_AVAILBLE_FAIL, data: {msg: message}})
        }
    } catch (err) {
        yield put({ type: ZIP_CODE_AVAILBLE_FAIL, data: {msg: "NET ERROR"}});
    }
}

export function* watchZipcodeavailable(){
  yield takeLatest(ZIP_CODE_AVAILBLE, zipcodeavailable)
}

function* requestpickup(payload) {
    try {
        const result = yield requestpickupApi(payload.data);
        if (result) {
            yield put({ type: REQUEST_PICKUP_SUCCESS, data: {msg: result.message}})
        } else {
            let message = result.message;
            let errors = result.errors || {};
            for (let item of Object.keys(errors)) {
                let error_part = errors[item];
                for (let msg of error_part) {
                    message += ' ' + msg;
                }
            }
            yield put({ type: REQUEST_PICKUP_FAIL, data: {msg: message}})
        }
    } catch (err) {
        yield put({ type: REQUEST_PICKUP_FAIL, data: {msg: "NET ERROR"}});
    }
}

export function* watchRequestpickup(){
  yield takeLatest(REQUEST_PICKUP, requestpickup)
}

function* requestNewZip(payload) {
    try {
        const result = yield requestNewZipApi(payload.data);

        if (result) {
            yield put({ type: REQUEST_NEW_ZIP_SUCCESS, data: {msg: result.message}})
        } else {
            let message = result.message;
            let errors = result.errors || {};
            for (let item of Object.keys(errors)) {
                let error_part = errors[item];
                for (let msg of error_part) {
                    message += ' ' + msg;
                }
            }
            yield put({ type: REQUEST_NEW_ZIP_FAIL, data: {msg: message}})
        }
    } catch (err) {
        yield put({ type: REQUEST_NEW_ZIP_FAIL, data: {msg: "NET ERROR"}});
    }
}

export function* watchRequestNewZip(){
  yield takeLatest(REQUEST_NEW_ZIP, requestNewZip)
}