import React from 'react';
import './Review.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import verified from '../../Assets/Icons/verified.png';
import StarRatings from 'react-star-ratings';
import { StarSVGViewBox, StarSVGIconPath } from '../../Constant';
import { toggleFeedbackModal } from '../../Redux/Actions';
// import ReactFlagsSelect from 'react-flags-select';
import moment from "moment";

function innerFunc(item) {
    return {__html: item.feedback}
}

class Review extends React.Component {

    readOnModal = () => {
        this.props.toggleFeedbackModal(true, { ...this.props.info, index: this.props.index || 0 });
    }

    render() {
        const { className, info, index } = this.props;
        const showAll = this.props.showAll || false;

        return (
            <div className={`review-component v-r round-object ${className || ''}`}>
                <div className="review-header">
                    <div className={`avatar v-r h-c v-c user-${index%3}`}>{(info.client_name.split(' ')[0]).charAt(0)}{info.client_name.split(' ').length > 1? (info.client_name.split(' ')[1]).charAt(0) : ''}</div>
                    <div className="reviewer-info v-r">
                        <h3 className="v-c">{info.client_name}<img src={verified} alt="verify-mark" /></h3>
                        <div className="address v-c">
                            {info.city} • {moment(info.rating_date).format('ll')}
                        </div>
                    </div>
                </div>
                <div className="star-area v-c">
                    <div className="mr-5">
                        <p>Presentation</p>
                        <StarRatings
                            rating={Number(info.presentation)}
                            starDimension="17px"
                            starSpacing="2.14px"
                            starRatedColor="#FBA92E"
                            starEmptyColor="rgba(48, 42, 50, 0.1)"
                            svgIconViewBox={StarSVGViewBox}
                            svgIconPath={StarSVGIconPath}
                        />
                    </div>
                    <div>
                        <p>Knowledge</p>
                        <StarRatings
                            rating={Number(info.knowledge)}
                            starDimension="17px"
                            starSpacing="2.14px"
                            starRatedColor="#FBA92E"
                            starEmptyColor="rgba(48, 42, 50, 0.1)"
                            svgIconViewBox={StarSVGViewBox}
                            svgIconPath={StarSVGIconPath}
                        />                        
                    </div>
                </div>
                <div className="feedback-title">Feedback</div>
                <p className="review" dangerouslySetInnerHTML={innerFunc(info)} />
                {!showAll && <p className="continue-btn" onClick={e =>this.readOnModal()}>Continue Reading</p> }
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, { 
    toggleFeedbackModal
 })(withRouter(Review));
